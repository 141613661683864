import React from "react";

import { renderRTF } from "../rich-text";

import RightDashedLine from "../svg/right-line";
import LeftDashedLine from "../svg/left-line";

const LinkedCard = ({data}) => {
  return (
    <div
      className={`p-4 transition rounded-xl bg-blue-light`}
    >
      {data.heading && <p className="text-sm lg:text-base font-semibold">{data.heading}</p>}
      {data.subHeading && <p className="mt-5 font-light">{data.subHeading}</p>}
      { data.link && (<p className="mt-5">
        <a href={data.link} className="text-accent font-medium hover:underline">Read more</a>
      </p>)}
    </div>
  )
}

export default function SectionWithLinkedCards({ data }) {

  const textColor = data.backgroundColor === 'bg-secondary' || data.backgroundColor === 'bg-accent' ? 'text-white' : 'text-secondary';
  const bg = data.backgroundColor === 'bg-white' ? '' : `${data.backgroundColor} section`
  
  return (
    <section className={`${bg} ${textColor} section px-4`}>
      <div className={`rounded-none md:rounded-3xl max-w-7xl mx-auto`}>
        {data.text && (
          <div className={`container max-w-5xl mb-12 title`}>
            <div className={`flex-wrap justify-center text-center flex -mx-4`}>
              <div className={`w-full md:w-10/12 px-4`}>
                {renderRTF(data.text)}
              </div>
            </div>
          </div>
        )}
        <div className="container lg:-mb-6">
          <div className="flex flex-wrap justify-center items-center -mx-4 text-center">
            {data.cards.slice(0, 3).map((i, idx, arr) => {

              if (idx === 0) {
                return (
                  <>
                    <div className={`w-1/2 sm:w-1/3 md:w-1/5 md:my-4 transform md:-translate-y-10 px-2 md:px-0 order-1 sm:order-1`}
                      key={idx}
                    >
                      <LinkedCard data={i} idx={idx} isLast={idx === arr.length - 1} />
                    </div>
                    <div className="w-1/3 hidden md:block md:w-1/5 lg:w-auto relative transform md:translate-y-6 z-10 order-2 sm:order-2">
                      <div className="absolute -left-2 -top-2">
                        <div className={`bg-accent w-5 h-5 rounded-full relative z-10 absolute top-0`}>
                        </div>
                      </div>
                      <div className="absolute -left-2 -top-2">
                        <div className={`bg-accent w-5 h-5 rounded-full relative z-10 animate-ping`}>
                        </div>
                      </div>
                      <LeftDashedLine />
                    </div>
                  
                  </>
                )
              }

              if (idx === (arr.length - 1)) {
                return (
                  <>
                    <div className="w-1/3 hidden md:block md:w-1/5 lg:w-auto order relative transform md:translate-y-6 z-10 order-4 sm:order-4">
                      <div className="absolute -right-2 -top-2">
                        <div className={`bg-accent w-5 h-5 rounded-full relative z-10 absolute top-0`}>
                        </div>
                      </div>
                      <div className="absolute -right-2 -top-2">
                        <div className={`bg-accent w-5 h-5 rounded-full relative z-10 animate-ping`}>
                        </div>
                      </div>
                      <RightDashedLine />
                    </div>

                    <div className={`w-1/2 sm:w-1/3 md:w-1/5 md:my-4 transform md:-translate-y-10 px-2 md:px-0 order-3 sm:order-5`} key={idx}
                    >
                      <LinkedCard data={i} idx={idx} isLast={idx === arr.length - 1} />
                    </div>

                  </>
                )
              }

              return (
                <div className={`w-1/2 sm:w-1/3 md:w-1/5 transform md:translate-y-28 translate-y-4 relative z-20 px-2 md:px-0 order-5 sm:order-3`}
                  key={idx}
                >
                  <LinkedCard data={i} idx={idx} isLast={idx === arr.length - 1} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
