import React from "react";

const CardBlank = ({ data, cardClass }) => {
  return (
    <div
      className={`${cardClass} transition hover:shadow-xl rounded-lg hover:opacity-80 cursor-pointer`}
    >
      {data.heading && <p className="mt-6 mb-5 text-xl font-semibold">{data.heading}</p>}
      {data.subHeading && <p className="font-light">{data.subHeading}</p>}
      { data.link && (<p className="mt-5">
        <a href={data.link} className="text-accent font-medium hover:underline">Read more</a>
      </p>)}
    </div>
  )
}

export default CardBlank;