import React from "react";

function LeftDashedLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="181"
      height="127"
      fill="none"
      viewBox="0 0 181 127"
      className="block w-full"
    >
      <path
        stroke="#42566C"
        strokeDasharray="10 10"
        strokeOpacity="0.3"
        strokeWidth="3"
        d="M2 2c17.833 17.167 59.8 78.2 99 75 39.2-3.2 70.833 40.333 78.5 48"
      ></path>
    </svg>
  );
}

export default LeftDashedLine;
