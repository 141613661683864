import React from "react";

import { renderRTF } from "../rich-text";

import Card from "../card/main";
import SectionWithLinkedCards from "./section-linked-card";

export default function SectionWithCards({ data }) {

  const textColor = data.backgroundColor === 'bg-secondary' || data.backgroundColor === 'bg-accent' ? 'text-white' : 'text-secondary';
  const bg = data.backgroundColor === 'bg-white' ? 'bg-white' : `${data.backgroundColor} section`
  
  const arr = data.cards;

  const rowClass =
    Array.from(arr).length === 5
      ? "md:w-1/3 xl:w-1/5"
      : Array.from(arr).length === 6
      ? "md:w-1/3 xl:w-1/6"
      : "md:w-1/4";
  const cardClass =
    Array.from(arr).length === 5
      ? "p-4 md:p-4 lg:p-5"
      : Array.from(arr).length === 6
      ? "p-3 md:p-3 lg:p-5"
      : "p-4 md:p-4 lg:p-6";


  if (data.cardType === 'linked') {
    return <SectionWithLinkedCards data={data} />
  }

  return (
    <section className={`${bg} ${textColor} section px-4`}>
      <div className={`rounded-none md:rounded-3xl max-w-7xl mx-auto`}>
        {data.text && (
          <div className={`container max-w-5xl mb-12 title`}>
            <div className={`flex-wrap justify-center text-center flex -mx-4`}>
              <div className={`w-full md:w-10/12 px-4`}>
                {renderRTF(data.text)}
              </div>
            </div>
          </div>
        )}
        <div className="container lg:-mb-6">
          <div className={`flex-wrap items-center flex -mx-4`}>
            <div className={`w-full  px-4`}>
              <div className="flex flex-wrap justify-center -mx-4">
                {data.cards.map((i, idx, arr) => {
                  return (
                    <div className={`
                      ${rowClass} 
                      ${ data.cardCentred ? "text-center" : ""} w-1/2  my-4 px-2`}
                      key={idx}
                    >
                      <Card cardClass={cardClass} data={i} backgroundColor={data.backgroundColor} idx={idx} isLast={idx === arr.length - 1} type={data.cardType} isCentered={data.cardCentred} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
