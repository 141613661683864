import React from "react";

function RightDashedLine() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="183"
      height="142"
      fill="none"
      viewBox="0 0 183 142"
      className="block w-full"
    >
      <path
        stroke="#42566C"
        strokeDasharray="10 10"
        strokeOpacity="0.3"
        strokeWidth="3"
        d="M1 140c23.667 4 85-20.5 96-54s65-83.5 85.5-84.5"
      ></path>
    </svg>
  );
}

export default RightDashedLine;
