import React from "react";

const CardIconSolid = ({ data, isCentered, cardClass }) => {

  if (data.link) {
    return (
      <a
        href={data.link}
        className={`${cardClass} transition hover:shadow-xl rounded-lg hover:opacity-80 cursor-pointer`}
      >
        {data.icon && <div className={`${isCentered ? "mx-auto" : ""} w-16 h-16 flex justify-start items-start rounded-xl text-white font-bold text-xl`}>
          <img src={data.icon.file.url} loading="lazy" width={51} height={51} alt={data.icon.title} />
        </div>}
        
        <p className="mt-6 text-base md:text-lg font-medium">{data.heading}</p>
        {data.subHeading && <p className="text-sm md:text-base mt-5 font-light">{data.subHeading}</p>}
      </a>
    )
  }
  
  
  return (
    <div
      className={`${cardClass} rounded-lg`}
    >
      {data.icon && <div className={`${isCentered ? "mx-auto" : ""} w-16 h-16 flex justify-start items-start rounded-xl text-white font-bold text-xl`}>
        <img src={data.icon.file.url} loading="lazy" width={51} height={51} alt={data.icon.title} />
      </div>}
      
      <p className="mt-6 text-base md:text-lg font-medium">{data.heading}</p>
      {data.subHeading && <p className="text-sm md:text-base mt-5 font-light">{data.subHeading}</p>}
    </div>
  )
}

export default CardIconSolid;