import React from "react";

const CardOrder = ({ 
  isCentered,
  data,
  idx,
  isLast,
  arr,
  cardClass
  }) => {

  if (data.link) {
    return (
      <a
        href={data.link}
        className={`${cardClass} transition hover:shadow-xl rounded-lg hover:opacity-80 cursor-pointer`}
      >
        {idx !== 0 && <div className="relative">
          <div className={`${isLast ? "border-accent" : "border-secondary"} z-0 absolute transform left-1 transform -translate-x-full -right-6 top-6  border-dashed border-t h-px hidden md:block`} />
        </div>}
        <div className={`
          ${ isCentered ? "mx-auto" : ""} 
          ${isLast ? "bg-accent" : "bg-secondary"}
          w-12 h-12 flex justify-center items-center rounded-full text-white relative z-10`}>
          <div className="lg:text-xl text-lg font-medium">{++idx}</div>
        </div>
        <p className="mt-4 mb-3 lg:mt-6 lg:mb-5 lg:text-xl text-lg font-semibold">{data.heading}</p>
        {data.subHeading && <p className="text-sm lg:text-base font-light">{data.subHeading}</p>}
        { data.link && (<p className="mt-5">
          <a href={data.link} className="text-accent font-medium hover:underline">Read more</a>
        </p>)}
      </a>
    )
  }

  return (
    <div
      className={`${cardClass} rounded-lg`}
    >
      {idx !== 0 && <div className="relative">
        <div className={`${isLast ? "border-accent" : "border-secondary"} z-0 absolute transform left-1 transform -translate-x-full -right-6 top-6  border-dashed border-t h-px hidden md:block`} />
      </div>}
      <div className={`
        ${ isCentered ? "mx-auto" : ""} 
        ${isLast ? "bg-accent" : "bg-secondary"}
        w-12 h-12 flex justify-center items-center rounded-full text-white relative z-10`}>
        <div className="lg:text-xl text-lg font-medium">{++idx}</div>
      </div>
      <p className="mt-4 mb-3 lg:mt-6 lg:mb-5 lg:text-xl text-lg font-semibold">{data.heading}</p>
      {data.subHeading && <p className="text-sm lg:text-base font-light">{data.subHeading}</p>}
    </div>
  )
}

export default CardOrder;