import React from "react";

import CardOrder from "./order";
import CardIcon from "./icon";
import CardBlank from "./blank";
import CardIconSolid from "./icon-solid";
import CardDot from "./dot";

const Card = ({data, type, isCentered, isLast, idx, backgroundColor, cardClass}) => {
  // types
  // blank order icon

  if (type === 'order') {
    return <CardOrder cardClass={cardClass} data={data} idx={idx} isCentered={isCentered} isLast={isLast} />
  }

  if (type === 'dot') {
    return <CardDot cardClass={cardClass} data={data} idx={idx} isCentered={isCentered} isLast={isLast} backgroundColor={backgroundColor} />
  }

  if (type === 'icon') {
    return <CardIcon cardClass={cardClass} data={data} isCentered={isCentered} />
  }

  if (type === 'icon-solid') {
    return <CardIconSolid cardClass={cardClass} data={data} isCentered={isCentered} />
  }

  if (type === 'blank') {
    return <CardBlank cardClass={cardClass} data={data} isCentered={isCentered} />
  }
  return ''
}

export default Card;