import React from "react";

const CardDot = ({ 
  isCentered,
  data,
  idx,
  isLast,
  backgroundColor,
  cardClass
  }) => {

    
  if (backgroundColor === "bg-secondary")  {
    return (
      <div
        className={`${cardClass} rounded-lg`}
      >
        {idx !== 0 && <div className="relative">
          <div className={`${isLast ? "border-accent" : "border-white"} 
          z-0 absolute transform -left-0.5 -right-9 top-3 border-dashed border-t h-px hidden md:block transform -translate-x-full`} />
        </div>}
        <div className="relative">
          <div className={`
            ${isLast ? "bg-accent" : "bg-blue-light"}
            w-6 h-6 rounded-full relative z-10 absolute top-0`}>
          </div>
          <div className="absolute top-0">
            <div className={`
              ${isLast ? "bg-accent" : "bg-blue-light"}
              w-6 h-6 rounded-full relative z-10 animate-ping`}>
            </div>
          </div>
        </div>
  
        <p className="mt-4 mb-3 lg:mt-6 lg:mb-5 lg:text-xl text-lg font-semibold">{data.heading}</p>
        {data.subHeading && <p className="text-sm lg:text-base font-light">{data.subHeading}</p>}
        { data.link && (<p className="mt-5">
          <a href={data.link} className="text-accent font-medium hover:underline">Read more</a>
        </p>)}
      </div>
    )
  } 
    
  return (
    <div
      className={`${cardClass} rounded-lg`}
    >
      {idx !== 0 && <div className="relative">
        <div className={`${isLast ? "border-accent" : "border-secondary"} 
        z-0 absolute transform -left-0.5 -right-9 top-3 border-dashed border-t h-px hidden md:block transform -translate-x-full`} />
      </div>}
      <div className="relative">
        <div className={`
          ${isLast ? "bg-accent" : "bg-secondary"}
          w-6 h-6 rounded-full relative z-10 absolute top-0`}>
        </div>
        <div className="absolute top-0">
          <div className={`
            ${isLast ? "bg-accent" : "bg-secondary"}
            w-6 h-6 rounded-full relative z-10 animate-ping`}>
          </div>
        </div>
      </div>

      <p className="mt-4 mb-3 lg:mt-6 lg:mb-5 lg:text-xl text-lg font-semibold">{data.heading}</p>
      {data.subHeading && <p className="text-sm lg:text-base font-light">{data.subHeading}</p>}
      { data.link && (<p className="mt-5">
        <a href={data.link} className="text-accent font-medium hover:underline">Read more</a>
      </p>)}
    </div>
  )
}

export default CardDot;